// ========================================================================
// Imports & Requires
// ========================================================================

// Custom Bootstrap Import
//--------------------------------------
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
import "bootstrap/js/dist/util";

// Miscellaneous Imports
//--------------------------------------
import "@fancyapps/fancybox";
import ResponsiveBackgroundImage from "./lib/responsive-background-image";
var imagesLoaded = require("imagesloaded");
import Isotope from "isotope-layout";
import "share-buttons";
import animateScrollTo from "animated-scroll-to";
import ScrollSnap from "scroll-snap";
// import 'jquery-hoverintent';

// MapBox
import StylesControl from "mapbox-gl-controls/lib/styles";
import CompassControl from "mapbox-gl-controls/lib/compass";
import ZoomControl from "mapbox-gl-controls/lib/zoom";

// import AOS from 'aos';

var imagesLoaded = require("imagesloaded");
imagesLoaded.makeJQueryPlugin($);

// ========================================================================
// Responsive Background Images
// ========================================================================
createResponsiveBackgroundImages("#content");
document.body.addEventListener("htmx:afterSettle", function () {
  // All sprig components are wrapped by div.component
  // Setup responsive bg images after a sprig event completes
  createResponsiveBackgroundImages('[id^="component-"]');
});
function createResponsiveBackgroundImages(parentSelector) {
  let elements = document.querySelectorAll(
    parentSelector + " .responsive-background-image"
  );
  for (let i = 0; i < elements.length; i++) {
    new ResponsiveBackgroundImage(elements[i]);
  }
}

// ========================================================================
// Main Navigation
// ========================================================================
let pageCover = document.getElementById("page-cover");
if (pageCover == null) {
  pageCover = document.getElementById("fullscreen-slider");
}
if (pageCover == null) {
  pageCover = document.getElementById("page-cover-video");
}
let header = document.getElementById("nav-primary");
if (pageCover != null && window.innerWidth > 768) {
  window.addEventListener("scroll", handleScroll);
} else {
  header.classList.replace("transparent", "opaque");
}
function handleScroll() {
  var scroll = window.scrollY;
  if (scroll >= pageCover.scrollHeight - header.scrollHeight) {
    header.classList.replace("transparent", "opaque");
  } else {
    header.classList.replace("opaque", "transparent");
  }
}

// ========================================================================
// Window Resize Handler
// ========================================================================
window.addEventListener("resize", windowResizeHandler);
function windowResizeHandler() {
  if (window.innerWidth <= 768) {
    header.classList.replace("transparent", "opaque");
  } else {
    $("#navbarCollapse").collapse("hide");
  }
}

// ========================================================================
// AOS - Animate on Scroll
// ========================================================================
// AOS.init({
//     // Global settings via https://samuelweber.at/article/how-to-use-aos-js-in-your-project
//     disable: 'phone', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
//     startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
//     initClassName: 'aos-init', // class applied after initialization
//     animatedClassName: 'aos-animate', // class applied on animation
//     useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
//     disableMutationObserver: false, // disables automatic mutations' detections (advanced)
//     debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
//     throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
//
//     // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
//     offset: 160, // offset (in px) from the original trigger point
//     delay: 150, // values from 0 to 3000, with step 50ms
//     duration: 600, // values from 0 to 3000, with step 50ms
//     easing: 'ease', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: true, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
// });

// ========================================================================
// Back to Top Scroll To
// ========================================================================
var backToTopLinks = document.getElementsByClassName("back-to-top");
for (let i = 0; i < backToTopLinks.length; i++) {
  backToTopLinks[i].addEventListener("click", function (event) {
    event.preventDefault();
    animateScrollTo(0);
  });
}

// ========================================================================
// Down Arrow Scroll To
// ========================================================================
var downArrow = document.getElementById("down-arrow");
if (downArrow != null) {
  downArrow.addEventListener("click", function (event) {
    event.preventDefault();
    let summary = document.getElementById("summary");
    let summaryTopY = summary.getBoundingClientRect().top + window.scrollY;
    animateScrollTo(summaryTopY);
  });
}

// ========================================================================
// Scroll Snap Setup
// ========================================================================
const snapConfig = {
  snapDestinationX: "0",
  snapDestinationY: "100%", // px|%|vw|vh (vh has side effects on phones)
  timeout: 100, //ms after which scrolling is considered finished default 100
  duration: 300, //default is 300
  threshold: 0.075, // threshold to reach before scrolling to next/prev element, expressed as a percentage in the range [0, 1]
  // easing: t => (t<.5 ? 2*t*t : -1+(4-2*t)*t),
};

function scrollSnapCallback() {
  window.console.log("element snapped");
}

const snapContainer = document.getElementById("fullscreen-slider");
// disable on phones to avoid side effects with chrome's back/forward nav.
if (snapContainer != null && window.innerWidth > 576) {
  const snapObject = new ScrollSnap(snapContainer, snapConfig);
  snapObject.bind(scrollSnapCallback);
}

// ========================================================================
// Local Insights
// ========================================================================
let localInsightToggles = document.getElementsByClassName("info-toggle");
for (let i = 0; i < localInsightToggles.length; i++) {
  localInsightToggles[i].addEventListener("click", function (event) {
    event.preventDefault();
    let parent = event.target.parentNode;
    if (parent.classList.contains("closed")) {
      parent.classList.replace("closed", "open");
    } else {
      parent.classList.replace("open", "closed");
    }
  });
}

// ========================================================================
// General Functions
// ========================================================================
// Navigation function for toggling dropdowns on hover properly
function toggleDropdown(e) {
  const _d = $(e.target).closest(".dropdown"),
    _m = $(".dropdown-menu", _d);
  setTimeout(
    function () {
      const shouldOpen = e.type !== "click" && _d.is(":hover");
      _m.toggleClass("show", shouldOpen);
      _d.toggleClass("show", shouldOpen);
      $('[data-toggle="dropdown"]', _d).attr("aria-expanded", shouldOpen);
    },
    e.type === "mouseleave" ? 50 : 0
  );
}

// ========================================================================
// jQuery
// ========================================================================
$(document).ready(function () {
  windowResizeHandler(); // call one time to setup the page

  $('[data-fancybox="page-gallery"]').fancybox({
    buttons: [
      "zoom",
      //"share",
      "slideShow",
      "fullScreen",
      //"download",
      //"thumbs",
      "close",
    ],
  });

  // toggle dropdowns properly in addition to css :hover
  if (window.innerWidth > 768) {
    $("body")
      .on("mouseenter mouseleave", ".dropdown", toggleDropdown)
      .on("click", ".dropdown-menu a", toggleDropdown);
  }

  if (window.matchMedia("(min-width: 768px)").matches) {
    $(".animated-icon").hover(
      function () {
        $(this).removeClass("svg-hoverout").addClass("svg-hoverin");
      },
      function () {
        $(this).removeClass("svg-hoverin").addClass("svg-hoverout");
      }
    );
  }

  var filtersGroup = document.querySelector(".isotope-filters-group");
  if (filtersGroup != null) {
    // Re-layout the iso grid after images load because images define height
    imagesLoaded(".isotope-grid", function () {
      // Initialize Isotope
      var isoGrid = new Isotope(".isotope-grid", {
        itemSelector: ".isotope-item",
        percentPosition: true,
        layoutMode: "fitRows",
        fitRows: {
          gutter: 0,
        },
      });

      // Bind Isotope filter buttons click event
      filtersGroup.addEventListener("click", function (event) {
        var filterValue = event.target.getAttribute("data-filter");
        if (filterValue != null) {
          isoGrid.arrange({ filter: filterValue });
          filtersGroup.querySelector(".active").classList.remove("active");
          event.target.classList.add("active");
        }
      });
      $(".isotope-grid").on("show.bs.collapse", function (e) {
        handleVendorCollapseToggle(e, "show");
        eval("isoGrid.layout()");
      });

      $(".isotope-grid").on("hide.bs.collapse", function (e) {
        handleVendorCollapseToggle(e, "hide");
      });

      $(".isotope-grid").on("hidden.bs.collapse", function () {
        eval("isoGrid.layout()");
      });
    });
  }

  // Focus Search Form after search modal opens
  $("#modal-search").on("shown.bs.modal", function () {
    $("#search-query").focus();
  });

  // populate the hidden field in the itinerary share form
  var shareItineraryURL = $("#shareItineraryURL").html();
  if (shareItineraryURL != "") {
    $("#form-input-itineraryShareLink").val(shareItineraryURL);
  }

  function handleVendorCollapseToggle(e, toggleEventType) {
    var vendorID = $(e.target).data("parent");
    var vendorDetails = $(vendorID + "Details");
    var vendor = $(vendorID);

    if (toggleEventType == "show") {
      vendor.height(vendor.height() + vendorDetails.height());
    } else if (toggleEventType == "hide") {
      vendor.height("");
    }
  }

  // Setup Map Controls
  // ---------------------------------------------------------------
  let maps = document.getElementsByClassName("mapboxgl-map");
  if (maps != null) {
    Array.prototype.forEach.call(maps, function (map) {
      var myMap = eval(map.id);
      myMap.addControl(new CompassControl(), "top-right");
      myMap.addControl(new ZoomControl(), "top-right");
      myMap.addControl(
        new StylesControl({
          styles: [
            {
              label: "Terrain",
              styleName: "Outdoors",
              styleUrl: "mapbox://styles/mapbox/outdoors-v11",
            },
            {
              label: "Satellite",
              styleName: "Satellite",
              styleUrl: "mapbox://styles/mapbox/satellite-v9",
            },
          ],
          onChange: (style) => console.log(style),
        }),
        "top-left"
      );
      myMap.addControl(new mapboxgl.FullscreenControl());
    });
  }

  // Add To Itinerary Event Handlers
  // ---------------------------------------------------------------
  $(document).on("click", ".itinerary-add", function () {
    let hostname = window.location.origin;
    let entryID = $(this).data("id");
    let urlTarget = hostname + "/itinerary/add/" + entryID;
    $.ajax({
      url: urlTarget,
      type: "GET",
      contentType: "text/plain",
      xhrFields: { withCredentials: true },
      crossDomain: true,
    }).done(function (data) {
      $("#modal-body-added").html(data);
      $("#modal-body-adding").addClass("collapse");
      incrementTripPlannerBadge(1);
      $("#modal-add-to-itinerary").modal("show");
    });
  });

  $("#modal-add-to-itinerary").on("hidden.bs.modal", function () {
    // Reset the modal
    $("#modal-body-adding").removeClass("collapse");
    $("#modal-body-added").html("");
  });

  // Remove From Itinerary Event Handlers
  // ---------------------------------------------------------------
  $(document).on("click", ".itinerary-remove", function () {
    let hostname = window.location.origin;
    let entryID = $(this).data("id");
    let urlTarget = hostname + "/itinerary/remove/" + entryID;
    $.ajax({
      url: urlTarget,
      type: "GET",
      contentType: "text/plain",
      xhrFields: { withCredentials: true },
      crossDomain: true,
    }).done(function (data) {
      $("#modal-body-removed").html(data);
      $("#modal-body-removing").addClass("collapse");
      incrementTripPlannerBadge(-1);
      $("#modal-remove-from-itinerary").modal("show");
    });
  });
  $("#modal-remove-from-itinerary").on("hidden.bs.modal", function () {
    let removedEntryID = $("#updatedItinerary").data("updatedId");
    // Reset the modal
    $("#modal-body-removing").removeClass("collapse");
    $("#modal-body-removed").html("");
    let removedEntryReference = "#entry" + removedEntryID;
    $(removedEntryReference).slideUp();
  });

  function incrementTripPlannerBadge(incrementBy) {
    let badgeCount = $("#badge-count");
    let count = badgeCount.html() * 1; // cast to an int by multiplying * 1
    badgeCount.html(count + incrementBy);
  }
});
